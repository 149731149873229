<template>
  <div class="absolute top-12 flex w-full items-center justify-center">
    <div class="flex flex-col justify-end">
      <div class="bg-redAccent border-b border-white items-center flex flex-row flex-wrap lg:flex-nowrap gap-x-2 gap-y-1 lg:gap-6 px-3 lg:px-6 pt-1 pb-2 lg:pb-1 "
        v-scroll-spy-active="{class: 'active-link'}"
        v-scroll-spy-link="{selector: 'button.menu-link'}"
      >

        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:TopLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link ml-1"
          :text="trans('ABOUT:WhatLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:WhyLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:HowWeLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:HowToLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link"
          :text="trans('ABOUT:AuthorLink')"
        />
        <Button
          variant="homepageLink"
          class="menu-link mr-1"
          :text="trans('ABOUT:SubscribeLink')"
        />
      </div>
      <div class="flex flex-row justify-between">
        <div></div>
        <div v-if="!isNarrowMobileDevice"
          class="flex flex-row justify-end bg-white border-b border-solid border-redAccent"
        >
          <div class="flex flex-row w-full px-3 gap-3 py-1">
            <span class="flex flex-row gap-1 text-xs items-center">{{ trans('LABEL:DataSecurity1') }} </span><a href="https://veriff.com" target="_blank"><img class="h-3.5 mb-1" :src="this.themeImages.aboutVeriff"/></a>
            <span class="flex flex-row gap-1 text-xs items-center">{{ trans('LABEL:DataSecurity2') }} </span><a href="https://durex.pl" target="_blank"><img class="h-3 mb-0.5" :src="this.themeImages.aboutDurex"/></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {isNarrowMobileDevice} from "@/common/helpers/utils";
import themeConfig from "@/themeConfig";

export default {
  name: "NavigationAbout",
  components: {Button},
  data() {
    return {
      isNarrowMobileDevice: isNarrowMobileDevice(),
      themeImages: themeConfig.staticPhotos,
    }
  }
}
</script>

<style lang="scss">
  .active-link {
    color: theme('colors.white') !important;
    opacity: .6 !important;
    &:hover {
      cursor: default;
    }
  }
</style>